





















import { PropType } from 'vue';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';

export enum StepState {
  Inactive,
  Active,
  Complete,
}

@Component
export default class SessionConfigurationStep extends Mixins(DeviceWidthCheckerMixin) {
  public readonly StepState = StepState;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Number as PropType<StepState>, required: true }) readonly state!: StepState;
}
